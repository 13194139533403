export default '<svg viewBox="0 0 58 59" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M46.2951 25.7985C48.2052 25.7985 49.7537 24.2471 49.7537 22.3335C49.7537 20.4198 48.2052 18.8684 46.2951 18.8684C44.3849 18.8684 42.8364 20.4198 42.8364 22.3335C42.8364 24.2471 44.3849 25.7985 46.2951 25.7985Z" fill="black"/>\n' +
'<path d="M14.4586 25.9301C16.3688 25.9301 17.9173 24.3787 17.9173 22.465C17.9173 20.5514 16.3688 19 14.4586 19C12.5485 19 11 20.5514 11 22.465C11 24.3787 12.5485 25.9301 14.4586 25.9301Z" fill="black"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M9.63037 42.2482C9.63037 42.2482 10.5042 37.2971 16.2392 38.4668" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M29.1264 33.6187C27.8051 33.6638 26.4904 33.4125 25.2786 32.883C25.275 32.9123 25.275 32.942 25.2786 32.9713C25.2559 33.4922 25.3387 34.0123 25.522 34.5003C25.7053 34.9883 25.9853 35.434 26.3452 35.8106C26.7051 36.1873 27.1374 36.487 27.616 36.6918C28.0946 36.8966 28.6097 37.0021 29.1301 37.0021C29.6505 37.0021 30.1655 36.8966 30.6442 36.6918C31.1228 36.487 31.5551 36.1873 31.9149 35.8106C32.2748 35.434 32.5549 34.9883 32.7382 34.5003C32.9215 34.0123 33.0043 33.4922 32.9816 32.9713V32.883C31.7669 33.4119 30.45 33.6632 29.1264 33.6187Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M54.5916 12.6141C47.7404 9.30361 40.1623 8.45023 36.7917 8.2001C36.3066 8.16838 35.8517 7.95317 35.519 7.59803C35.1864 7.24289 35.0008 6.77438 35 6.28735V5.63995C34.9995 5.37758 35.0527 5.1179 35.1563 4.87692C35.2599 4.63595 35.4116 4.41878 35.6022 4.23885C35.7928 4.05893 36.0182 3.92005 36.2645 3.83079C36.5108 3.74152 36.7727 3.70376 37.0341 3.71984C40.7497 3.9479 48.93 4.8675 56.5523 8.55323C56.7868 8.66761 56.9958 8.82841 57.1666 9.02593C57.3374 9.22344 57.4665 9.45359 57.5461 9.70246C57.6257 9.95134 57.6542 10.2138 57.6298 10.474C57.6054 10.7342 57.5286 10.9867 57.4041 11.2164L57.0957 11.7828C56.8652 12.2176 56.4764 12.5466 56.01 12.7014C55.5436 12.8562 55.0356 12.825 54.5916 12.6141Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M3.08904 9.21688C9.87415 5.77392 17.4376 4.80284 20.8008 4.4865C21.2844 4.44373 21.7338 4.21868 22.0582 3.85682C22.3826 3.49496 22.5578 3.02324 22.5485 2.53696V1.88957C22.5443 1.62698 22.4864 1.36804 22.3784 1.12876C22.2704 0.889482 22.1145 0.674948 21.9204 0.498428C21.7263 0.321907 21.4981 0.187151 21.25 0.102493C21.0018 0.0178357 20.739 -0.014918 20.4777 0.00624567C16.7621 0.307872 8.60378 1.38931 1.05497 5.22953C0.822095 5.34763 0.615642 5.51198 0.448176 5.71256C0.28071 5.91314 0.155736 6.14577 0.0808597 6.39628C0.00598285 6.64679 -0.0172296 6.90994 0.0126335 7.16972C0.0424966 7.4295 0.124811 7.68048 0.254568 7.90738L0.577659 8.45914C0.820456 8.8829 1.21593 9.19752 1.68293 9.33842C2.14992 9.47932 2.65303 9.43583 3.08904 9.21688Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'<path d="M25.2084 55.4867C32.4419 57.0569 40.6546 52.87 41.8923 47.1689L13.4751 41C12.2375 46.7011 17.975 53.9164 25.2084 55.4867Z" fill="white"/>\n' +
'</svg>\n'
