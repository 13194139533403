export default '<svg viewBox="0 0 61 52" xmlns="http://www.w3.org/2000/svg">\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M30.6433 31.5452C29.322 31.5903 28.0073 31.339 26.7954 30.8095V30.8978C26.7954 31.9202 27.2008 32.9007 27.9224 33.6237C28.644 34.3466 29.6228 34.7528 30.6433 34.7528C31.6638 34.7528 32.6425 34.3466 33.3641 33.6237C34.0857 32.9007 34.4911 31.9202 34.4911 30.8978V30.8095C33.2793 31.339 31.9646 31.5903 30.6433 31.5452Z" fill="black"/>\n' +
'</g>\n' +
'<path d="M47.819 23.725C49.7292 23.725 51.2776 22.1736 51.2776 20.26C51.2776 18.3463 49.7292 16.7949 47.819 16.7949C45.9088 16.7949 44.3604 18.3463 44.3604 20.26C44.3604 22.1736 45.9088 23.725 47.819 23.725Z" fill="black"/>\n' +
'<path d="M13.4674 23.725C15.3776 23.725 16.9261 22.1736 16.9261 20.26C16.9261 18.3463 15.3776 16.7949 13.4674 16.7949C11.5573 16.7949 10.0088 18.3463 10.0088 20.26C10.0088 22.1736 11.5573 23.725 13.4674 23.725Z" fill="black"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M17.9102 43.3013C17.9102 43.3013 20.4583 49.9224 30.6433 49.9224C40.8283 49.9224 43.3764 43.3013 43.3764 43.3013" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M56.9757 9.76188C54.5081 7.98929 51.7114 6.72919 48.7504 6.05578C45.7893 5.38237 42.7236 5.30926 39.7339 5.84074C39.2806 5.92221 38.8132 5.83678 38.4178 5.60019C38.0224 5.3636 37.7257 4.99179 37.5824 4.55331L37.3841 3.9427C37.2976 3.6828 37.2679 3.40733 37.2969 3.13493C37.3259 2.86252 37.413 2.59953 37.5522 2.36376C37.6915 2.12799 37.8797 1.92494 38.1041 1.76835C38.3284 1.61176 38.5838 1.50528 38.8528 1.45612C42.4567 0.78125 46.1601 0.84939 49.7369 1.65638C53.3137 2.46337 56.6887 3.99227 59.656 6.14972C59.8676 6.30404 60.0451 6.50044 60.1774 6.72663C60.3097 6.95282 60.394 7.20393 60.425 7.46426C60.4561 7.72459 60.4331 7.98853 60.3577 8.23957C60.2822 8.49061 60.1558 8.72335 59.9865 8.92322L59.5752 9.41612C59.2669 9.79389 58.8266 10.0399 58.3438 10.1041C57.8609 10.1683 57.3718 10.046 56.9757 9.76188Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M4.28852 9.76188C6.75616 7.98929 9.55283 6.72919 12.5139 6.05578C15.475 5.38237 18.5406 5.30925 21.5303 5.84074C21.9835 5.92063 22.4502 5.83452 22.8452 5.59815C23.2403 5.36177 23.5372 4.99091 23.6819 4.55331L23.8801 3.93534C23.9653 3.67542 23.9941 3.40026 23.9646 3.12829C23.935 2.85631 23.8478 2.59381 23.7087 2.35836C23.5697 2.12291 23.382 1.91995 23.1582 1.76308C22.9345 1.60621 22.6799 1.49904 22.4115 1.44876C18.8093 0.777006 15.1084 0.847385 11.5343 1.65561C7.96016 2.46383 4.58782 3.99295 1.62294 6.14972C1.41316 6.3054 1.23734 6.50237 1.10626 6.72856C0.975185 6.95474 0.891618 7.20537 0.860677 7.46507C0.829735 7.72477 0.85208 7.98807 0.926337 8.23881C1.00059 8.48955 1.1252 8.72245 1.29251 8.92322L1.71105 9.41612C2.01859 9.78785 2.45465 10.0299 2.93231 10.0939C3.40998 10.158 3.8942 10.0395 4.28852 9.76188Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
