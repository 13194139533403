export default '<svg viewBox="0 0 64 50" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M18.565 17.1275C17.9602 16.258 17.1545 15.5477 16.2167 15.0572C15.2789 14.5667 14.2366 14.3106 13.1787 14.3106C12.1208 14.3106 11.0785 14.5667 10.1407 15.0572C9.2029 15.5477 8.39728 16.258 7.79248 17.1275" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<path d="M56.4043 17.1275C55.7995 16.258 54.9939 15.5477 54.0561 15.0572C53.1182 14.5667 52.076 14.3106 51.0181 14.3106C49.9602 14.3106 48.9179 14.5667 47.9801 15.0572C47.0423 15.5477 46.2366 16.258 45.6318 17.1275" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M17.0962 39.7054C17.0962 39.7054 20.0996 47.5477 32.113 47.5477C44.1265 47.5477 47.1225 39.7054 47.1225 39.7054" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M32.1132 28.5452C30.7919 28.5903 29.4773 28.339 28.2654 27.8095C28.2654 27.8095 28.2654 27.8684 28.2654 27.8978C28.2427 28.4187 28.3255 28.9388 28.5088 29.4268C28.6921 29.9148 28.9722 30.3605 29.332 30.7371C29.6919 31.1138 30.1242 31.4135 30.6028 31.6183C31.0814 31.8231 31.5965 31.9286 32.1169 31.9286C32.6373 31.9286 33.1524 31.8231 33.631 31.6183C34.1096 31.4135 34.5419 31.1138 34.9018 30.7371C35.2617 30.3605 35.5417 29.9148 35.725 29.4268C35.9083 28.9388 35.9911 28.4187 35.9684 27.8978V27.8095C34.7541 28.3393 33.4369 28.5907 32.1132 28.5452Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M60.8912 7.19593C53.7242 4.62107 46.102 4.62107 42.7241 4.75349C42.2394 4.77322 41.7652 4.60805 41.3973 4.2913C41.0293 3.97455 40.795 3.52979 40.7415 3.04673L40.668 2.41405C40.6397 2.1536 40.6648 1.8901 40.7419 1.63975C40.819 1.38941 40.9465 1.15752 41.1164 0.958359C41.2863 0.759198 41.4951 0.596978 41.7299 0.481683C41.9647 0.366388 42.2206 0.300457 42.4818 0.287948C46.2048 0.118743 54.4365 0.14817 62.4112 2.99523C62.6568 3.08303 62.882 3.21991 63.0731 3.39756C63.2642 3.57521 63.4173 3.78993 63.5231 4.02868C63.6288 4.26742 63.6851 4.52521 63.6883 4.78639C63.6916 5.04758 63.6418 5.3067 63.5421 5.54802L63.2924 6.14392C63.1017 6.59312 62.7468 6.9523 62.3004 7.14788C61.854 7.34346 61.3499 7.36066 60.8912 7.19593Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M3.32806 7.19593C10.4877 4.62107 18.1246 4.62107 21.5025 4.75349C21.9872 4.77322 22.4613 4.60805 22.8293 4.2913C23.1973 3.97455 23.4316 3.52979 23.4851 3.04673L23.5512 2.39933C23.5796 2.13889 23.5544 1.87538 23.4773 1.62504C23.4002 1.3747 23.2728 1.14281 23.1029 0.943645C22.933 0.744484 22.7242 0.582264 22.4894 0.466969C22.2545 0.351674 21.9987 0.285744 21.7374 0.273235C18.0144 0.10403 9.78273 0.133457 1.81536 2.98052C1.56852 3.06667 1.34187 3.2025 1.14936 3.37965C0.956853 3.5568 0.802555 3.77153 0.695947 4.01064C0.589338 4.24975 0.532672 4.50817 0.529443 4.77003C0.526214 5.03189 0.576488 5.29164 0.677167 5.53331L0.926833 6.12921C1.11479 6.58109 1.46855 6.94357 1.91524 7.14201C2.36194 7.34045 2.86759 7.35975 3.32806 7.19593Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
