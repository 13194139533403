export default '<svg viewBox="0 0 101 120" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M94.3271 34.7493C92.3431 34.7901 90.3898 35.2495 88.5942 36.0974C87.131 16.2867 70.3674 0.526665 50.0593 0.526665C29.8017 0.526665 13.0696 16.2107 11.537 35.9708C9.83549 35.2123 7.99985 34.8034 6.13834 34.7683C0.809046 34.8632 0.071144 42.1862 0.512624 47.9522C1.14331 56.3829 5.38151 61.921 11.1712 59.9779C11.2955 59.9381 11.4177 59.8916 11.537 59.8387C12.0479 71.1048 14.1039 79.0608 17.806 83.9977C19.9693 86.8775 24.9075 89.694 30.4891 91.9346V99.6627C30.4891 104.867 32.549 109.857 36.2155 113.537C39.8821 117.216 44.8551 119.284 50.0404 119.284V119.284C55.2257 119.284 60.1986 117.216 63.8652 113.537C67.5318 109.857 69.5916 104.867 69.5916 99.6627V91.9473C75.1732 89.7067 80.1115 86.8838 82.2747 84.0103C85.9958 79.0481 88.0455 71.0479 88.5816 59.7121C88.8122 59.8169 89.0479 59.9099 89.2879 59.9906C95.0776 61.8894 99.3032 56.3639 99.9465 47.9649C100.394 42.1799 99.6564 34.8442 94.3271 34.7493Z" />\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M94.3271 34.7493C92.3431 34.7901 90.3898 35.2495 88.5942 36.0974C88.6636 37.0278 88.714 37.9582 88.714 38.9013V53.9651C88.714 55.9714 88.6699 57.8766 88.5879 59.7121C88.8185 59.8169 89.0542 59.9098 89.2943 59.9906C95.084 61.8894 99.3095 56.3639 99.9528 47.9649C100.394 42.1799 99.6564 34.8442 94.3271 34.7493Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M11.4046 53.9651V38.9013C11.4046 37.9076 11.455 36.9266 11.537 35.9518C9.8355 35.1933 7.99985 34.7844 6.13834 34.7493C0.809046 34.8442 0.071144 42.1672 0.512624 47.9332C1.14331 56.3639 5.38151 61.902 11.1712 59.9589C11.2955 59.9191 11.4177 59.8726 11.537 59.8197C11.455 58.0032 11.4046 56.0221 11.4046 53.9651Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M8.88213 51.5093C7.02791 51.6928 5.7287 50.8764 5.31245 49.2497C4.81421 47.237 6.85763 45.6927 8.81906 47.0091C8.81906 47.0091 9.55066 41.8191 4.73853 40.8571" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M90.9595 51.5093C92.8137 51.6928 94.1129 50.8764 94.5292 49.2497C95.0274 47.237 92.9903 45.6927 91.0225 47.0091C91.0225 47.0091 90.2909 41.8191 95.1031 40.8571" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M50.0593 96.612C45.2472 96.612 37.3825 94.7132 30.5081 91.9663V99.6944C30.5081 104.898 32.5679 109.889 36.2345 113.568C39.9011 117.248 44.874 119.315 50.0593 119.315C55.2446 119.315 60.2176 117.248 63.8841 113.568C67.5507 109.889 69.6106 104.898 69.6106 99.6944V91.9473C62.7361 94.7132 54.8715 96.612 50.0593 96.612Z" fill="black"/>\n' +
'</g>\n' +
'</svg>\n'
