import facialHair1 from '@/assets/images/avatar/facialHairs/facialHair1'
import facialHair2 from '@/assets/images/avatar/facialHairs/facialHair2'
import facialHair3 from '@/assets/images/avatar/facialHairs/facialHair3'
import facialHair4 from '@/assets/images/avatar/facialHairs/facialHair4'
import facialHair5 from '@/assets/images/avatar/facialHairs/facialHair5'
import facialHair6 from '@/assets/images/avatar/facialHairs/facialHair6'

export default [
  {
    id: 1,
    src: facialHair1,
    position: {
      top: '57%',
      left: '34.7%',
      width: '30%'
    }
  },
  {
    id: 2,
    src: facialHair2,
    position: {
      top: '57.3%',
      left: '40.2%',
      width: '20%'
    }
  },
  {
    id: 3,
    src: facialHair3,
    position: {
      top: '70%',
      left: '45%',
      width: '10%'
    }
  },
  {
    id: 4,
    src: facialHair4,
    position: {
      top: '52.5%',
      left: '28.5%',
      width: '42.5%'
    }
  },
  {
    id: 5,
    src: facialHair5,
    position: {
      top: '55%',
      left: '28.7%',
      width: '42.2%'
    }
  },
  {
    id: 6,
    src: facialHair6,
    position: {
      top: '55%',
      left: '28.7%',
      width: '42.2%'
    }
  }
]
