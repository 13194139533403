import hair1 from '@/assets/images/avatar/hairs/hair-1'
import hair2 from '@/assets/images/avatar/hairs/hair-2'
import hair3 from '@/assets/images/avatar/hairs/hair-3'
import hair4 from '@/assets/images/avatar/hairs/hair-4'
import hair5 from '@/assets/images/avatar/hairs/hair-5'
import hair6 from '@/assets/images/avatar/hairs/hair-6'
import hair7 from '@/assets/images/avatar/hairs/hair-7'
import hair8 from '@/assets/images/avatar/hairs/hair-8'
import hair9 from '@/assets/images/avatar/hairs/hair-9'
import hair10 from '@/assets/images/avatar/hairs/hair-10'
import hair11 from '@/assets/images/avatar/hairs/hair-11'
import hair12 from '@/assets/images/avatar/hairs/hair-12'

export default [
  {
    id: 1,
    src: hair1,
    position: {
      top: '15%',
      left: '27%',
      width: '60%'
    }
  },
  {
    id: 2,
    src: hair2,
    position: {
      top: '17%',
      left: '27.5%',
      width: '45%'
    }
  },
  {
    id: 3,
    src: hair3,
    position: {
      top: '16%',
      left: '24.5%',
      width: '50%'
    }
  },
  {
    id: 4,
    src: hair4,
    position: {
      top: '17%',
      left: '26.5%',
      width: '47%'
    }
  },
  {
    id: 5,
    src: hair5,
    position: {
      top: '10%',
      left: '25%',
      width: '50%'
    }
  },
  {
    id: 6,
    src: hair6,
    position: {
      top: '16%',
      left: '25%',
      width: '50%'
    }
  },
  {
    id: 7,
    src: hair7,
    position: {
      top: '17%',
      left: '27%',
      width: '46%'
    }
  },
  {
    id: 8,
    src: hair8,
    position: {
      top: '30%',
      left: '27.2%',
      width: '45%'
    }
  },
  {
    id: 9,
    src: hair9,
    position: {
      top: '15%',
      left: '20%',
      width: '60%'
    }
  },
  {
    id: 10,
    src: hair10,
    position: {
      top: '15%',
      left: '25%',
      width: '50%'
    }
  },
  {
    id: 11,
    src: hair11,
    position: {
      top: '17%',
      left: '23%',
      width: '60%'
    }
  },
  {
    id: 12,
    src: hair12,
    position: {
      top: '13%',
      left: '19%',
      width: '55%'
    }
  }
]
