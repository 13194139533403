export default '<svg viewBox="0 0 97 122" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M90.7165 37.0722C89.4422 37.0772 88.1763 37.28 86.9639 37.6735V37.2241C86.9639 16.1159 69.5696 0.881233 48.3092 0.881233C27.055 0.881233 9.66071 16.1159 9.66071 37.2241V37.6735C8.44826 37.28 7.18245 37.0772 5.90816 37.0722C0.572563 37.1672 -0.165368 44.4902 0.282419 50.2562C0.913105 58.4147 4.88012 63.8642 10.3734 62.4781C12.8961 73.9278 21.7257 85.9788 31.3374 91.9664V104.403C31.3366 106.64 31.7748 108.855 32.627 110.921C33.4792 112.987 34.7288 114.865 36.3042 116.447C37.8797 118.028 39.7502 119.283 41.809 120.139C43.8677 120.995 46.0744 121.436 48.3028 121.436V121.436C52.804 121.436 57.1209 119.641 60.3037 116.447C63.4865 113.253 65.2746 108.921 65.2746 104.403V91.979C74.9052 85.9915 83.7159 73.9405 86.2386 62.4908C91.7382 63.8769 95.6989 58.4273 96.3296 50.2689C96.7837 44.5092 96.0395 37.1672 90.7165 37.0722Z" />\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M90.7172 37.0722C89.4429 37.0772 88.177 37.28 86.9646 37.6735V56.0285C86.96 58.2087 86.7189 60.3819 86.2456 62.5098C91.7452 63.8959 95.7059 58.4463 96.3366 50.2879C96.7844 44.5092 96.0402 37.1672 90.7172 37.0722Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M9.63572 55.9969V37.6419C8.42328 37.2483 7.15744 37.0455 5.88317 37.0406C0.54762 37.1355 -0.190264 44.4586 0.257518 50.2246C0.888198 58.383 4.85517 63.8326 10.3484 62.4465C9.87952 60.3287 9.64057 58.1663 9.63572 55.9969V55.9969Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M7.68664 53.8386C5.83238 54.0158 4.53315 53.2057 4.11689 51.5727C3.61864 49.56 5.6558 48.0156 7.62358 49.3321C7.62358 49.3321 8.35522 44.1421 3.54297 43.18" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M88.939 53.8386C90.7932 54.0158 92.0924 53.2057 92.5087 51.5727C93.0069 49.56 90.9698 48.0156 89.0021 49.3321C89.0021 49.3321 88.2704 44.1421 93.0826 43.18" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M48.3092 97.6754C43.1439 97.6754 37.0956 95.5741 31.3438 91.9791V104.416C31.3429 106.653 31.7812 108.867 32.6334 110.934C33.4856 113 34.7351 114.878 36.3106 116.459C37.8861 118.041 39.7566 119.296 41.8153 120.152C43.8741 121.008 46.0807 121.448 48.3092 121.448C52.8104 121.448 57.1272 119.654 60.31 116.46C63.4929 113.266 65.2809 108.933 65.2809 104.416V91.9791C59.5291 95.5488 53.4808 97.6754 48.3092 97.6754Z" fill="black"/>\n' +
'</g>\n' +
'</svg>\n'
