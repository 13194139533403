export default '<svg viewBox="0 0 64 55" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M49.2951 24.7985C51.2052 24.7985 52.7537 23.2471 52.7537 21.3335C52.7537 19.4198 51.2052 17.8684 49.2951 17.8684C47.3849 17.8684 45.8364 19.4198 45.8364 21.3335C45.8364 23.2471 47.3849 24.7985 49.2951 24.7985Z" fill="black"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M17.7266 43.2419C17.7266 43.2419 19.1438 50.2161 29.1893 51.9008C39.2348 53.5854 42.8403 47.4426 42.8403 47.4426" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<path d="M20.3335 22.5841C19.7287 21.7145 18.9231 21.0042 17.9853 20.5138C17.0474 20.0233 16.0052 19.7671 14.9473 19.7671C13.8893 19.7671 12.8471 20.0233 11.9093 20.5138C10.9715 21.0042 10.1658 21.7145 9.56104 22.5841" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M12.6304 41.2482C12.6304 41.2482 13.5042 36.2971 19.2392 37.4668" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M32.1264 32.6187C30.8051 32.6638 29.4904 32.4125 28.2786 31.883C28.275 31.9123 28.275 31.942 28.2786 31.9713C28.2559 32.4922 28.3387 33.0123 28.522 33.5003C28.7053 33.9883 28.9853 34.434 29.3452 34.8106C29.7051 35.1873 30.1374 35.487 30.616 35.6918C31.0946 35.8966 31.6097 36.0021 32.1301 36.0021C32.6505 36.0021 33.1655 35.8966 33.6442 35.6918C34.1228 35.487 34.5551 35.1873 34.9149 34.8106C35.2748 34.434 35.5549 33.9883 35.7382 33.5003C35.9215 33.0123 36.0043 32.4922 35.9816 31.9713V31.883C34.7669 32.4119 33.45 32.6632 32.1264 32.6187V32.6187Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M60.4857 9.61415C53.6345 6.30361 46.0563 5.45023 42.6858 5.2001C42.2007 5.16838 41.7457 4.95317 41.4131 4.59803C41.0804 4.24289 40.8949 3.77438 40.894 3.28735V2.63995C40.8936 2.37758 40.9468 2.1179 41.0503 1.87692C41.1539 1.63595 41.3057 1.41878 41.4963 1.23885C41.6869 1.05893 41.9123 0.920052 42.1586 0.830785C42.4048 0.741519 42.6667 0.703759 42.9281 0.719844C46.6438 0.947903 54.8241 1.8675 62.4463 5.55323C62.6809 5.66761 62.8898 5.82841 63.0607 6.02593C63.2315 6.22344 63.3606 6.45359 63.4402 6.70246C63.5198 6.95134 63.5482 7.21379 63.5238 7.47398C63.4994 7.73418 63.4226 7.98672 63.2981 8.21637L62.9897 8.78284C62.7593 9.21758 62.3704 9.54658 61.904 9.70141C61.4377 9.85625 60.9297 9.82499 60.4857 9.61415V9.61415Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M3.84783 14.7418C10.6329 11.2989 18.1964 10.3278 21.5596 10.0114C22.0432 9.96866 22.4926 9.74361 22.817 9.38175C23.1414 9.01989 23.3166 8.54818 23.3073 8.06189V7.4145C23.3031 7.15192 23.2452 6.89297 23.1372 6.65369C23.0292 6.41442 22.8733 6.19988 22.6792 6.02336C22.4851 5.84684 22.2569 5.71208 22.0088 5.62743C21.7606 5.54277 21.4978 5.51001 21.2365 5.53118C17.5209 5.83281 9.36257 6.91424 1.81376 10.7545C1.58088 10.8726 1.37443 11.0369 1.20697 11.2375C1.0395 11.4381 0.914526 11.6707 0.839649 11.9212C0.764772 12.1717 0.741559 12.4349 0.771423 12.6947C0.801286 12.9544 0.8836 13.2054 1.01336 13.4323L1.33645 13.9841C1.57924 14.4078 1.97472 14.7224 2.44171 14.8634C2.90871 15.0043 3.41182 14.9608 3.84783 14.7418V14.7418Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
