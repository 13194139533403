export default '<svg viewBox="0 0 100 120" xmlns="http://www.w3.org/2000/svg">\n' +
'  <path d="M94.2029 34.6482C92.2214 34.6874 90.2702 35.1445 88.4762 35.99C87.013 16.1855 70.2431 0.41922 49.9413 0.41922C29.6837 0.41922 12.9453 16.1033 11.4127 35.8634C9.71427 35.1031 7.87985 34.6961 6.02032 34.6671C0.684716 34.7558 -0.0531437 42.0851 0.388337 47.8511C1.01902 56.2818 5.26348 61.8136 11.0469 59.8768C11.3284 59.7809 11.6043 59.6689 11.8731 59.5414C14.1688 70.643 22.0587 77.7761 25.7482 82.7383C27.4132 84.9535 30.3144 87.4536 32.938 89.7891V102.163C32.938 104.4 33.377 106.614 34.2299 108.681C35.0828 110.747 36.3329 112.625 37.9089 114.207C39.4849 115.788 41.3558 117.043 43.415 117.899C45.4741 118.755 47.681 119.195 49.9097 119.195C52.1382 119.195 54.3449 118.755 56.4036 117.899C58.4624 117.042 60.3329 115.788 61.9084 114.206C63.4839 112.624 64.7334 110.747 65.5857 108.68C66.4379 106.614 66.876 104.399 66.8752 102.163V89.8271C69.4989 87.4916 72.4 84.9915 74.065 82.7763C77.805 77.7951 85.758 70.6113 88.0095 59.4211C88.3738 59.6166 88.7535 59.7817 89.1448 59.9148C94.9282 61.8136 99.1538 56.2881 99.8034 47.8891C100.276 42.0914 99.5385 34.7621 94.2029 34.6482Z" />\n' +
'  <g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'    <path d="M8.76363 51.4335C6.90944 51.6107 5.6103 50.8006 5.19405 49.1676C4.69582 47.1612 6.73287 45.6169 8.70058 46.927C8.70058 46.927 9.43218 41.7433 4.62012 40.7749" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'  </g>\n' +
'  <g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'    <path d="M90.8408 51.4335C92.695 51.6107 93.9943 50.8006 94.4105 49.1676C94.9088 47.1612 92.8654 45.6169 90.8976 46.927C90.8976 46.927 90.166 41.7433 94.9845 40.7749" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'  </g>\n' +
'  <g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'    <path d="M94.2024 34.6481C92.2208 34.6873 90.2696 35.1445 88.4757 35.99C88.545 36.9204 88.5892 37.8888 88.5892 38.8002V53.8576C88.5885 55.7173 88.4048 57.5724 88.0405 59.3958C88.4048 59.5913 88.7845 59.7564 89.1757 59.8895C94.9591 61.7882 99.1848 56.2628 99.8344 47.8638C100.276 42.0914 99.538 34.7621 94.2024 34.6481Z" fill="black"/>\n' +
'  </g>\n' +
'  <g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'    <path d="M11.2865 53.8829V38.8255C11.2865 37.8318 11.337 36.8444 11.4127 35.8697C9.71428 35.1094 7.87986 34.7024 6.02032 34.6735C0.684716 34.7621 -0.0531439 42.0914 0.388337 47.8574C1.01902 56.2881 5.26348 61.8199 11.0469 59.8831C11.3284 59.7872 11.6043 59.6752 11.8731 59.5477C11.4854 57.6845 11.2889 55.7863 11.2865 53.8829Z" fill="black"/>\n' +
'  </g>\n' +
'  <g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'    <path d="M49.941 98.0995C44.227 98.0995 37.958 94.3019 32.9692 89.8271V102.201C32.9692 106.718 34.7574 111.05 37.9402 114.244C41.123 117.439 45.4398 119.233 49.941 119.233C52.1695 119.233 54.3761 118.793 56.4349 117.936C58.4937 117.08 60.3642 115.826 61.9396 114.244C63.5151 112.662 64.7647 110.785 65.6169 108.718C66.4691 106.652 66.9073 104.437 66.9065 102.201V89.8271C61.9115 94.2766 55.6487 98.0995 49.941 98.0995Z" fill="black"/>\n' +
'  </g>\n' +
'</svg>'
