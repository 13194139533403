import baseNarrow from '@/assets/images/avatar/bases/base-narrow'
import baseUsual from '@/assets/images/avatar/bases/base-usual'
import baseWide from '@/assets/images/avatar/bases/base-wide'
import baseUltraWide from '@/assets/images/avatar/bases/base-ultrawide'

export default [
  {
    id: 1,
    src: baseNarrow,
    position: {
      top: '21%',
      left: '22.5%'
    }
  },
  {
    id: 2,
    src: baseUsual,
    position: {
      top: '19.5%',
      left: '22.5%'
    }
  },
  {
    id: 3,
    src: baseWide,
    position: {
      top: '22%',
      left: '22.5%'
    }
  },
  {
    id: 4,
    src: baseUltraWide,
    position: {
      top: '22%',
      left: '22.5%'
    }
  }
]
