import accessory1 from '@/assets/images/avatar/accessories/accessory1'
import accessory2 from '@/assets/images/avatar/accessories/accessory2'
import accessory3 from '@/assets/images/avatar/accessories/accessory3'
import accessory4 from '@/assets/images/avatar/accessories/accessory4'
import accessory5 from '@/assets/images/avatar/accessories/accessory5'
import accessory6 from '@/assets/images/avatar/accessories/accessory6'

export default [
  {
    id: 1,
    src: accessory1,
    position: {
      top: '45%',
      left: '30%',
      width: '40%'
    }
  },
  {
    id: 2,
    src: accessory2,
    position: {
      top: '53%',
      left: '25%',
      width: '50%'
    }
  },
  {
    id: 3,
    src: accessory3,
    position: {
      top: '10.5%',
      left: '27%',
      width: '46%',
      zIndex: 5
    }
  },
  {
    id: 4,
    src: accessory4,
    position: {
      top: '44%',
      left: '30%',
      width: '40%'
    }
  },
  {
    id: 5,
    src: accessory5,
    position: {
      top: '20%',
      left: '28%',
      width: '44%',
      zIndex: 5
    }
  },
  {
    id: 6,
    src: accessory6,
    position: {
      top: '52%',
      left: '26%',
      width: '48%'
    }
  }
]
