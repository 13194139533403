export default '<svg viewBox="0 0 62 56" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M44.1753 24.9735C44.7863 25.8344 45.594 26.5364 46.531 27.0208C47.468 27.5051 48.5071 27.7579 49.5615 27.7579C50.6159 27.7579 51.655 27.5051 52.592 27.0208C53.529 26.5364 54.3368 25.8344 54.9478 24.9735" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<path d="M6.35791 24.9735C6.96889 25.8344 7.77662 26.5364 8.71363 27.0208C9.65064 27.5051 10.6898 27.7579 11.7442 27.7579C12.7986 27.7579 13.8376 27.5051 14.7746 27.0208C15.7117 26.5364 16.5194 25.8344 17.1304 24.9735" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M40 51C37.0648 53.1442 34.0091 54.1505 30.9453 53.9818C27.9215 54.0412 24.9097 53.0551 22 51.0532" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M30.6567 32.8452C29.3354 32.8903 28.0207 32.6389 26.8088 32.1095C26.8088 32.1095 26.8088 32.1684 26.8088 32.1978C26.7861 32.7187 26.8689 33.2388 27.0523 33.7268C27.2356 34.2147 27.5156 34.6605 27.8755 35.0371C28.2354 35.4138 28.6677 35.7135 29.1463 35.9183C29.6249 36.123 30.1399 36.2286 30.6604 36.2286C31.1808 36.2286 31.6958 36.123 32.1745 35.9183C32.6531 35.7135 33.0854 35.4138 33.4453 35.0371C33.8051 34.6605 34.0852 34.2147 34.2685 33.7268C34.4518 33.2388 34.5346 32.7187 34.5119 32.1978V32.1095C33.2975 32.6393 31.9804 32.8907 30.6567 32.8452Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M21.2116 8.92934L21.3158 7.45088C21.3279 7.25312 21.3 7.05487 21.2338 6.86805C21.1675 6.68123 21.0642 6.50969 20.9302 6.36372C20.7961 6.21776 20.634 6.10038 20.4536 6.01862C20.2732 5.93687 20.0782 5.89243 19.8803 5.88798C16.5796 5.80099 8.47926 5.89847 1.66487 8.11229C1.47475 8.17413 1.2993 8.27419 1.14926 8.40636C0.99922 8.53852 0.877762 8.70001 0.792319 8.88092C0.706877 9.06182 0.659252 9.25834 0.652363 9.45843C0.645472 9.65852 0.679467 9.85798 0.752255 10.0446L1.29029 11.4184C1.42621 11.7659 1.6888 12.0488 2.02493 12.2101C2.36106 12.3713 2.74564 12.3987 3.10084 12.2869C9.19577 10.2882 16.6617 10.21 19.7163 10.2765C20.0902 10.2849 20.4532 10.1503 20.731 9.89999C21.0089 9.64967 21.1807 9.30251 21.2116 8.92934Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M39.9092 8.81404L39.8221 7.33447C39.8122 7.13658 39.8424 6.93867 39.9108 6.75263C39.9792 6.56659 40.0845 6.39626 40.2202 6.25185C40.356 6.10744 40.5194 5.99195 40.7008 5.91229C40.8821 5.83263 41.0776 5.79045 41.2756 5.78829C44.577 5.73949 52.6757 5.93071 59.4574 8.21998C59.6478 8.28328 59.8232 8.38499 59.9727 8.51882C60.1222 8.65265 60.2427 8.81576 60.3267 8.9981C60.4107 9.18044 60.4565 9.37814 60.4612 9.57899C60.466 9.77985 60.4296 9.97961 60.3542 10.1659L59.8003 11.5335C59.6642 11.8777 59.4037 12.1581 59.0705 12.3188C58.7374 12.4795 58.3562 12.5087 58.0029 12.4005C51.9315 10.3315 44.467 10.1668 41.4053 10.1947C41.0257 10.2029 40.658 10.0638 40.3791 9.80636C40.1002 9.54898 39.9318 9.19336 39.9092 8.81404Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
