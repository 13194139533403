export default '<svg viewBox="0 0 64 52" xmlns="http://www.w3.org/2000/svg">\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M60.3609 7.19593C53.194 4.62107 45.5644 4.62107 42.1939 4.75349C41.7083 4.77678 41.2322 4.61287 40.8635 4.29545C40.4947 3.97803 40.2613 3.53119 40.2112 3.04673L40.1378 2.41405C40.1094 2.1536 40.1346 1.8901 40.2117 1.63975C40.2888 1.38941 40.4162 1.15752 40.5861 0.958359C40.756 0.759198 40.9648 0.596978 41.1996 0.481683C41.4344 0.366388 41.6903 0.300457 41.9515 0.287948C45.6745 0.118743 53.9062 0.14817 61.8736 2.99523C62.1204 3.08138 62.3471 3.21721 62.5396 3.39436C62.7321 3.57152 62.8864 3.78625 62.993 4.02535C63.0996 4.26446 63.1563 4.52289 63.1595 4.78474C63.1628 5.0466 63.1125 5.30635 63.0118 5.54802L62.7621 6.14392C62.5715 6.59312 62.2166 6.9523 61.7701 7.14788C61.3237 7.34346 60.8196 7.36066 60.3609 7.19593Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M2.79778 7.19593C9.96474 4.62107 17.6237 4.62107 20.9648 4.75349C21.4504 4.77678 21.9265 4.61287 22.2952 4.29545C22.664 3.97803 22.8974 3.53119 22.9475 3.04673L23.0209 2.39933C23.0493 2.13889 23.0241 1.87538 22.947 1.62504C22.8699 1.3747 22.7425 1.14281 22.5726 0.943645C22.4027 0.744484 22.1939 0.582264 21.9591 0.466969C21.7243 0.351674 21.4684 0.285744 21.2072 0.273235C17.4842 0.10403 9.25245 0.133457 1.28509 2.98052C1.03824 3.06667 0.811597 3.2025 0.619088 3.37965C0.42658 3.5568 0.272277 3.77153 0.165669 4.01064C0.05906 4.24975 0.00239895 4.50817 -0.00083029 4.77003C-0.00405954 5.03189 0.0462113 5.29164 0.146891 5.53331L0.396559 6.12921C0.58452 6.58109 0.938268 6.94357 1.38497 7.14201C1.83167 7.34045 2.33731 7.35975 2.79778 7.19593Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'<path d="M18.0571 17.1275C17.441 16.2686 16.6324 15.5663 15.6964 15.077C14.7604 14.5877 13.7229 14.3249 12.6672 14.3099C11.6127 14.3255 10.5765 14.5885 9.6417 15.0778C8.70695 15.5672 7.89968 16.2692 7.28467 17.1275" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<path d="M55.8745 17.1275C55.2697 16.258 54.4641 15.5477 53.5263 15.0572C52.5884 14.5667 51.5462 14.3106 50.4883 14.3106C49.4304 14.3106 48.3881 14.5667 47.4503 15.0572C46.5125 15.5477 45.7069 16.258 45.1021 17.1275" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<path d="M31.5756 51.0201C38.9776 51.0201 46.1152 45.1862 46.1152 39.3523H17.0361C17.0361 45.1862 24.1737 51.0201 31.5756 51.0201Z" fill="white"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M31.5756 28.5452C30.2543 28.5903 28.9397 28.339 27.7278 27.8095C27.7242 27.8389 27.7242 27.8685 27.7278 27.8978C27.7051 28.4187 27.7879 28.9388 27.9712 29.4268C28.1545 29.9148 28.4346 30.3605 28.7944 30.7371C29.1543 31.1138 29.5866 31.4135 30.0652 31.6183C30.5438 31.8231 31.0589 31.9286 31.5793 31.9286C32.0997 31.9286 32.6148 31.8231 33.0934 31.6183C33.572 31.4135 34.0043 31.1138 34.3642 30.7371C34.724 30.3605 35.0041 29.9148 35.1874 29.4268C35.3707 28.9388 35.4535 28.4187 35.4308 27.8978V27.8095C34.2162 28.3384 32.8993 28.5897 31.5756 28.5452Z" fill="black"/>\n' +
'</g>\n' +
'</svg>\n'
