export default '<svg viewBox="0 0 65 53" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M50.6233 26.6387C52.6214 26.6387 54.2412 25.0218 54.2412 23.0272C54.2412 21.0327 52.6214 19.4157 50.6233 19.4157C48.6252 19.4157 47.0054 21.0327 47.0054 23.0272C47.0054 25.0218 48.6252 26.6387 50.6233 26.6387Z" fill="black"/>\n' +
'<path d="M14.6975 26.6387C16.6956 26.6387 18.3154 25.0218 18.3154 23.0272C18.3154 21.0327 16.6956 19.4157 14.6975 19.4157C12.6994 19.4157 11.0796 21.0327 11.0796 23.0272C11.0796 25.0218 12.6994 26.6387 14.6975 26.6387Z" fill="black"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M32.6564 34.7895C31.2741 34.8386 29.8984 34.5766 28.6313 34.0228V34.1224C28.6767 35.1587 29.121 36.1375 29.8716 36.8547C30.6222 37.572 31.6211 37.9724 32.6602 37.9724C33.6993 37.9724 34.6983 37.572 35.4489 36.8547C36.1995 36.1375 36.6438 35.1587 36.6891 34.1224C36.6891 34.1224 36.6891 34.0534 36.6891 34.0228C35.4203 34.5795 34.0414 34.8417 32.6564 34.7895Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M42.1355 49.7033C39.0461 50.6328 35.8287 51.0676 32.6029 50.9915C29.4194 51.0209 26.248 50.5949 23.1855 49.7263" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M59.0266 13.7078C56.8596 11.3957 54.2522 9.53861 51.3574 8.24555C48.4625 6.95249 45.3386 6.24948 42.1688 6.17776C41.6764 6.18075 41.2002 6.00205 40.8315 5.67597C40.4628 5.34989 40.2277 4.89942 40.1714 4.41105L40.1075 3.74936C40.0731 3.46625 40.0997 3.1791 40.1854 2.90716C40.2712 2.63522 40.4142 2.38475 40.6049 2.17255C40.7956 1.96034 41.0295 1.79129 41.291 1.67672C41.5524 1.56216 41.8355 1.50472 42.1211 1.50825C45.9572 1.55506 49.7439 2.38226 53.25 3.9393C56.756 5.49634 59.9078 7.75051 62.5126 10.5641C62.6971 10.7656 62.8382 11.0028 62.9272 11.261C63.0162 11.5191 63.0512 11.7927 63.0299 12.0648C63.0087 12.3369 62.9317 12.6017 62.8038 12.8429C62.6758 13.084 62.4996 13.2963 62.2861 13.4666L61.7638 13.8863C61.3705 14.2093 60.8686 14.3707 60.3602 14.3375C59.8518 14.3043 59.375 14.0792 59.0266 13.7078Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M5.90656 13.6266C8.10121 11.3331 10.733 9.50088 13.647 8.23772C16.5609 6.97457 19.6982 6.30602 22.8741 6.27144C23.3558 6.26711 23.8198 6.08909 24.1806 5.77019C24.5415 5.45129 24.7747 5.01301 24.8374 4.53613L24.931 3.8709C24.9684 3.58817 24.9449 3.30076 24.8621 3.02791C24.7792 2.75507 24.6389 2.50309 24.4505 2.28886C24.2621 2.07463 24.03 1.90309 23.7698 1.78573C23.5095 1.66838 23.2271 1.60792 22.9415 1.6084C19.1051 1.61422 15.3098 2.4009 11.7873 3.92039C8.26475 5.43987 5.08911 7.66024 2.45436 10.4458C2.26427 10.646 2.11782 10.8834 2.02428 11.1429C1.93074 11.4025 1.89213 11.6785 1.9109 11.9537C1.92967 12.2288 2.00541 12.497 2.13333 12.7413C2.26125 12.9857 2.43856 13.2008 2.65407 13.3732L3.1719 13.7984C3.56425 14.1215 4.06508 14.2836 4.57288 14.2517C5.08068 14.2198 5.55746 13.9963 5.90656 13.6266Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
