export default '<svg viewBox="0 0 62 58" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M47.6233 28.6387C49.6214 28.6387 51.2412 27.0218 51.2412 25.0272C51.2412 23.0327 49.6214 21.4157 47.6233 21.4157C45.6252 21.4157 44.0054 23.0327 44.0054 25.0272C44.0054 27.0218 45.6252 28.6387 47.6233 28.6387Z" fill="black"/>\n' +
'<path d="M11.6975 28.6387C13.6956 28.6387 15.3154 27.0218 15.3154 25.0272C15.3154 23.0327 13.6956 21.4157 11.6975 21.4157C9.69939 21.4157 8.07959 23.0327 8.07959 25.0272C8.07959 27.0218 9.69939 28.6387 11.6975 28.6387Z" fill="black"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M29.6564 36.7895C28.2741 36.8386 26.8984 36.5766 25.6313 36.0228V36.1224C25.6767 37.1587 26.121 38.1375 26.8716 38.8547C27.6222 39.572 28.6211 39.9724 29.6602 39.9724C30.6993 39.9724 31.6983 39.572 32.4489 38.8547C33.1995 38.1375 33.6438 37.1587 33.6891 36.1224C33.6891 36.1224 33.6891 36.0534 33.6891 36.0228C32.4203 36.5795 31.0414 36.8417 29.6564 36.7895Z" fill="black"/>\n' +
'</g>\n' +
'<path d="M29.5033 58C37.3908 58 45 51.5 45 45C40.005 46.6758 34.7777 47.5788 29.5033 47.6772C24.2267 47.5793 18.9972 46.6762 14 45C14 51.5 21.6092 58 29.5033 58Z" fill="white"/>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M21.2116 8.92934L21.3158 7.45088C21.3279 7.25312 21.3 7.05487 21.2338 6.86805C21.1675 6.68123 21.0642 6.50969 20.9302 6.36372C20.7961 6.21776 20.634 6.10038 20.4536 6.01862C20.2732 5.93687 20.0782 5.89243 19.8803 5.88798C16.5796 5.80099 8.47926 5.89847 1.66487 8.11229C1.47475 8.17413 1.2993 8.27419 1.14926 8.40636C0.99922 8.53852 0.877762 8.70001 0.792319 8.88092C0.706877 9.06182 0.659252 9.25834 0.652363 9.45843C0.645472 9.65852 0.679467 9.85798 0.752255 10.0446L1.29029 11.4184C1.42621 11.7659 1.6888 12.0488 2.02493 12.2101C2.36106 12.3713 2.74564 12.3987 3.10084 12.2869C9.19577 10.2882 16.6617 10.21 19.7163 10.2765C20.0902 10.2849 20.4532 10.1503 20.731 9.89999C21.0089 9.64967 21.1807 9.30251 21.2116 8.92934Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M39.9092 8.81404L39.8221 7.33447C39.8122 7.13658 39.8424 6.93867 39.9108 6.75263C39.9792 6.56659 40.0845 6.39626 40.2202 6.25185C40.356 6.10744 40.5194 5.99195 40.7008 5.91229C40.8821 5.83263 41.0776 5.79045 41.2756 5.78829C44.577 5.73949 52.6757 5.93071 59.4574 8.21998C59.6478 8.28328 59.8232 8.38499 59.9727 8.51882C60.1222 8.65265 60.2427 8.81576 60.3267 8.9981C60.4107 9.18044 60.4565 9.37814 60.4612 9.57899C60.466 9.77985 60.4296 9.97961 60.3542 10.1659L59.8003 11.5335C59.6642 11.8777 59.4037 12.1581 59.0705 12.3188C58.7374 12.4795 58.3562 12.5087 58.0029 12.4005C51.9315 10.3315 44.467 10.1668 41.4053 10.1947C41.0257 10.2029 40.658 10.0638 40.3791 9.80636C40.1002 9.54898 39.9318 9.19336 39.9092 8.81404Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
