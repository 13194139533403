export default '<svg viewBox="0 0 64 57" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M31.5759 57C43.9419 57 55.8672 47.2523 55.8672 37.5046H7.28467C7.28467 47.2523 19.21 57 31.5759 57Z" fill="white"/>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M31.5756 26.9403C30.2543 26.9854 28.9397 26.7341 27.7278 26.2047C27.7242 26.234 27.7242 26.2636 27.7278 26.2929C27.7051 26.8138 27.7879 27.3339 27.9712 27.8219C28.1545 28.3099 28.4346 28.7556 28.7944 29.1323C29.1543 29.5089 29.5866 29.8086 30.0652 30.0134C30.5438 30.2182 31.0589 30.3237 31.5793 30.3237C32.0997 30.3237 32.6148 30.2182 33.0934 30.0134C33.572 29.8086 34.0043 29.5089 34.3642 29.1323C34.724 28.7556 35.0041 28.3099 35.1874 27.8219C35.3707 27.3339 35.4535 26.8138 35.4308 26.2929V26.2047C34.2162 26.7335 32.8993 26.9848 31.5756 26.9403Z" fill="black"/>\n' +
'</g>\n' +
'<path d="M54.7069 12.6388L40.395 16.5747L54.7069 20.5179" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<path d="M8.45215 12.6388L22.764 16.5747L8.45215 20.5179" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'<g style="mix-blend-mode:hard-light">\n' +
'<path d="M60.3609 7.43024C53.194 4.87745 45.5644 4.84803 42.1939 4.9878C41.7083 5.01108 41.2322 4.84718 40.8635 4.52976C40.4947 4.21234 40.2613 3.76549 40.2112 3.28103L40.1378 2.63365C40.1094 2.3732 40.1346 2.10969 40.2117 1.85935C40.2888 1.609 40.4162 1.37712 40.5861 1.17796C40.756 0.978798 40.9648 0.816574 41.1996 0.701279C41.4344 0.585984 41.6903 0.520058 41.9515 0.507549C45.6745 0.338344 53.9062 0.367766 61.8736 3.21483C62.1204 3.30098 62.3471 3.43681 62.5396 3.61396C62.7321 3.79111 62.8864 4.00584 62.993 4.24494C63.0996 4.48405 63.1563 4.74248 63.1595 5.00434C63.1628 5.26619 63.1125 5.52594 63.0118 5.76762L62.7621 6.36351C62.5742 6.81539 62.2204 7.17788 61.7737 7.37632C61.327 7.57476 60.8214 7.59405 60.3609 7.43024Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'<path d="M2.79778 7.43024C9.96474 4.87745 17.5943 4.84803 20.9648 4.9878C21.4504 5.01108 21.9265 4.84718 22.2952 4.52976C22.664 4.21234 22.8974 3.76549 22.9475 3.28103L23.0209 2.63365C23.0493 2.3732 23.0241 2.10969 22.947 1.85935C22.8699 1.609 22.7425 1.37712 22.5726 1.17796C22.4027 0.978798 22.1939 0.816574 21.9591 0.701279C21.7243 0.585984 21.4684 0.520058 21.2072 0.507549C17.4842 0.338344 9.25245 0.367766 1.28509 3.21483C1.03824 3.30098 0.811597 3.43681 0.619088 3.61396C0.42658 3.79111 0.272277 4.00584 0.165669 4.24494C0.05906 4.48405 0.00239895 4.74248 -0.00083029 5.00434C-0.00405954 5.26619 0.0462113 5.52594 0.146891 5.76762L0.396559 6.36351C0.58452 6.81539 0.938268 7.17788 1.38497 7.37632C1.83167 7.57476 2.33731 7.59405 2.79778 7.43024Z" fill="#3C3C3C" fill-opacity="0.5"/>\n' +
'</g>\n' +
'</svg>\n'
