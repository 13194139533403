import face1 from '@/assets/images/avatar/faces/face1'
import face2 from '@/assets/images/avatar/faces/face2'
import face3 from '@/assets/images/avatar/faces/face3'
import face4 from '@/assets/images/avatar/faces/face4'
import face5 from '@/assets/images/avatar/faces/face5'
import face6 from '@/assets/images/avatar/faces/face6'
import face7 from '@/assets/images/avatar/faces/face7'
import face8 from '@/assets/images/avatar/faces/face8'
import face9 from '@/assets/images/avatar/faces/face9'

export default [
  {
    id: 1,
    src: face1,
    position: {
      top: '41.5%',
      left: '35.2%'
    }
  },
  {
    id: 2,
    src: face2,
    position: {
      top: '41.5%',
      left: '35%'
    }
  },
  {
    id: 3,
    src: face3,
    position: {
      top: '40.5%',
      left: '35%'
    }
  },
  {
    id: 4,
    src: face4,
    position: {
      top: '42%',
      left: '35%'
    }
  },
  {
    id: 5,
    src: face5,
    position: {
      top: '40.5%',
      left: '35%'
    }
  },
  {
    id: 6,
    src: face6,
    position: {
      top: '40.5%',
      left: '35%'
    }
  },
  {
    id: 7,
    src: face7,
    position: {
      top: '40%',
      left: '35%'
    }
  },
  {
    id: 8,
    src: face8,
    position: {
      top: '39%',
      left: '35.5%'
    }
  },
  {
    id: 9,
    src: face9,
    position: {
      top: '39%',
      left: '35%'
    }
  }
]
