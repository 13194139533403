export default '<svg viewBox="0 0 101 120" xmlns="http://www.w3.org/2000/svg">\n' +
'<path d="M94.7821 34.7493C92.8028 34.7903 90.8541 35.2474 89.0618 36.0911C87.5986 16.2867 70.816 0.526665 50.5142 0.526665C30.2566 0.526665 13.5118 16.2044 11.973 35.9708C10.277 35.2166 8.44794 34.8099 6.59321 34.7746C1.2576 34.8695 0.519692 42.1925 0.967479 47.9586C1.59817 56.3892 5.83639 61.9273 11.6261 59.9842L11.872 59.8956C12.1811 73.5986 19.8629 84.7382 31.1143 90.6878V99.669C31.1143 104.873 33.1741 109.863 36.8407 113.543C40.5073 117.223 45.4803 119.29 50.6656 119.29V119.29C55.8509 119.29 60.8238 117.223 64.4904 113.543C68.157 109.863 70.2168 104.873 70.2168 99.669V90.5105C81.3169 84.4977 88.8536 73.3897 89.1374 59.7627C89.3329 59.845 89.5285 59.9273 89.7303 59.9906C95.5137 61.8894 99.7392 56.3639 100.389 47.9649C100.849 42.1799 100.111 34.8442 94.7821 34.7493Z" />\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M94.7823 34.7493C92.8031 34.7903 90.8543 35.2474 89.062 36.0911C89.1314 37.0215 89.1629 37.9899 89.1629 38.9013V59.0348C89.1629 59.2754 89.1629 59.5222 89.1629 59.7627C89.3584 59.845 89.5539 59.9273 89.7558 59.9906C95.5391 61.8894 99.7648 56.3639 100.414 47.9649C100.85 42.1799 100.112 34.8442 94.7823 34.7493Z" fill="black"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M11.8594 59.0348V38.9013C11.8594 37.9076 11.8973 36.9202 11.973 35.9455C10.277 35.1913 8.44793 34.7846 6.59321 34.7493C1.2576 34.8442 0.519692 42.1672 0.967479 47.9332C1.59817 56.3639 5.83639 61.902 11.6261 59.9589L11.872 59.8703C11.8657 59.6108 11.8594 59.326 11.8594 59.0348Z" fill="black"/>\n' +
'</g>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M9.33698 51.5093C7.48276 51.6928 6.18354 50.8764 5.76729 49.2497C5.26904 47.237 7.30616 45.6927 9.2739 47.0091C9.2739 47.0091 10.0055 41.8191 5.19336 40.8571" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M91.4146 51.5093C93.2688 51.6928 94.568 50.8764 94.9843 49.2497C95.4825 47.237 93.4391 45.6927 91.4776 47.0091C91.4776 47.0091 90.746 41.8191 95.5582 40.8571" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>\n' +
'</g>\n' +
'<g style="mix-blend-mode:soft-light" opacity="0.5">\n' +
'<path d="M50.5146 95.3968C43.7633 95.4342 37.1046 93.8201 31.1147 90.6941V99.6754C31.1147 104.879 33.1746 109.87 36.8412 113.549C40.5077 117.229 45.4807 119.296 50.666 119.296V119.296C55.8513 119.296 60.8242 117.229 64.4908 113.549C68.1573 109.87 70.2172 104.879 70.2172 99.6754V90.5105C64.1534 93.7494 57.3837 95.4283 50.5146 95.3968Z" fill="black"/>\n' +
'</g>\n' +
'</svg>\n'
